// Constants
import {
  rentUnitContactKey,
  rentUnitStorageKey
} from "@/scripts/constant-types/localStorageKeys";

// Node Modules
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

// Services
import {
  getRentUnitContactValues,
  getRentUnitValues,
  setLocalStorageObject
} from "@/services/localStorageService";

// Types
import IBaseContextProvider from "@/interfaces/context/IBaseContextProvider";
import IRentUnit from "@/interfaces/context/rent-unit/IRentUnit";
import { IRentUnitContact } from "@/interfaces/context/rent-unit/IRentUnitContact";
import IRentUnitContext from "@/interfaces/context/rent-unit/IRentUnitContext";

const RentUnitContext = createContext<IRentUnitContext | undefined>(undefined);

const useRentUnitContext = () => {
  const context = useContext(RentUnitContext);
  if (context === undefined) {
    throw new Error("useRentUnitContext must be used within a RentUnitContextProvider");
  }

  return context;
};

const RentUnitContextProvider = ({
  children,
}: IBaseContextProvider) => {
  const [rentUnitValues, setRentUnitValues] = useState<IRentUnit>(getRentUnitValues());
  const [rentUnitContactValues, setRentUnitContactValues] = useState<IRentUnitContact>(getRentUnitContactValues());

  useEffect(() => {
    setLocalStorageObject(rentUnitStorageKey, rentUnitValues);
  }, [rentUnitValues]);

  useEffect(() => {
    setLocalStorageObject(rentUnitContactKey, rentUnitContactValues);
  }, [rentUnitContactValues]);

  const rentUnitContextValue: IRentUnitContext = {
    rentUnitValues,
    rentUnitContactValues,
    setRentUnitContactValues,
    setRentUnitValues,
  };

  return (
    <RentUnitContext.Provider
      value={rentUnitContextValue}
    >
      {children}
    </RentUnitContext.Provider>
  );
};

export {
  RentUnitContext,
  RentUnitContextProvider,
  useRentUnitContext,
};
