// Node Modules
import { useState } from "react";

// Scripts
import { replaceAll } from "@/scripts/stringUtils";

// Types
import { Data } from "@/src/models/Data";
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";
import IImageContent from "@/interfaces/payment/IImageContent";
import { ISelfStorageOption } from "@/interfaces/IWebContent";
import { ISplashScreenFieldsModified } from "@/interfaces/splash-screen/ISplashScreenFieldsModified";
import IStoreAddress from "@/interfaces/splash-screen/IStoreAddress";
import {
  IAttractionLoopInfo,
  IAttractionLoopInfoFields,
  IPaymentModals,
  IPaymentModalsFields,
} from "@/contentful-types";

export const environments = {
  development: "development",
  preview: "preview",
  production: "production",
};

export const useSubPageNavigation = (maximumPage, startingPage = 1) => {
  const [currentPage, setCurrentPage] = useState(startingPage);

  const handleForward = () => (maximumPage && startingPage < maximumPage) && setCurrentPage(current => current + 1);

  const handleBack = () => {
    if (currentPage === 1) {
      window.history.back();
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const resetCurrentPage = () => setCurrentPage(startingPage);

  return {
    currentPage,
    handleBack,
    handleForward,
    resetCurrentPage,
    setCurrentPage,
  };
};

/**
 *
 * @param modals represents the modals array coming from contentful.
 * @param key the identifier of each modal on contentful.
 * @returns the content of a specific modal found by its unique identifier.
 */
export const getModalContent = (modals: IPaymentModals[] | Data<IPaymentModals>[], key: string): IPaymentModalsFields => {
  const modal = modals?.find(x => x?.fields.uniqueKey[0] === key);
  return modal?.fields;
};

/**
 *
 * @returns the url of the NextJs api routes, depending on whether the app
 * is running on Localhost or Vercel.
 */
export const getApiRoutesUrl = (): string => {
  const apiRouteSuffix = "/api/";
  let baseUrl = "";

  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case "production":
    case "preview":
      baseUrl = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
      break;
    default:
      baseUrl = process.env.NEXT_PUBLIC_VERCEL_URL;
      break;
  }

  return `${baseUrl}${apiRouteSuffix}`;
};

export const getImageUrlContent = (storageOptions, key) => {
  const option = storageOptions?.find(x => x?.fields.uniqueKey[0] === key);

  const altText = option?.fields?.altText;
  const url = `https:${option?.fields?.image?.fields?.file?.url}`;
  const width = option?.fields?.image?.fields?.file?.details?.image?.width;
  const height = option?.fields?.image?.fields?.file?.details?.image?.height;
  return {
    altText,
    height,
    url,
    width,
  };
};

/**
 * Gets the month and year from a given date and concatenates them into a string separated by a "/".
 * @param date The date as either string or Date data type to get the Month and Year from it
 * @returns The Month and Year from a given date in the format: MM/YY, e.g. 01/22.
 */
export const getMonthDayAndYearFromDateAsString = (date: string | Date) => {
  if (!date || date == "") {
    return "";
  }

  const newDate = typeof date !== "string"
    ? date
    : new Date(date);

  const utcDay = newDate.getUTCDate();
  const utcMonth = newDate.getUTCMonth() + 1;
  const month: string = utcMonth < 10
    ? `0${utcMonth}`
    : utcMonth.toString();

  return `${month}/${utcDay}/${newDate.getUTCFullYear().toString().substring(2)}`;
};

/**
 * Gets the url and alt text from the unit image coming from Contentful.
 * @param storageOptions The array with all the storage options coming from Contentful.
 * @param unitDimensions The dimensions of the unit in the format: Small, 10’ x 10’.
 * @returns The url and alt text from the correct unit image.
 */
export const getSelectedUnitImageContent = (storageOptions: ISelfStorageOption[] | undefined, unitDimensions?: string): IImageContent => {
  let imageUrl: string = "";
  let imageAltText: string = "";
  const defaultSizeDimension: string = "Small, 5’ x 5’";
  let validatedUnitDimensions: string = !!unitDimensions
    ? unitDimensions
    : defaultSizeDimension;

  validatedUnitDimensions = validatedUnitDimensions.indexOf(",") == -1
    ? defaultSizeDimension
    : validatedUnitDimensions;

  if (storageOptions) {
    const dimensionsArray = validatedUnitDimensions.split(",");
    const dimensions = replaceAll(dimensionsArray[1].replace(/[^a-zA-Z0-9]/g, ""), " ", "");
    const size = dimensionsArray[0];
    const images = filterStorageOptions(storageOptions, dimensions, size);
    imageUrl = images[0]?.fields?.thumbnail?.fields?.image?.fields?.file?.url;
    imageAltText = images[0]?.fields?.thumbnail?.fields?.imageAltText;
  }

  return {
    imageUrl,
    imageAltText,
  };
};

/**
 * Returns the video information of the attraction loop video for a given brand or store.
 * @param content Contentful object that contains the attraction loop videos.
 * @param brand the brand for which to filter the attraction loop videos.
 * @param storeNumber the store number for which to filter the attraction loop videos.
 * @returns information needed to display attraction loop video.
 */
export const getAttractionLoopVideoInformation = (content: ISplashScreenFieldsModified, deviceInformation: IDeviceInformation): IAttractionLoopInfoFields => {
  const regularAttractionLoops = content?.attractionLoops.filter(attractionLoop => attractionLoop?.fields.key !== "OUTAGE")
  const attractionLoop = getAttractionLoopInfoByStoreNumberOrBrand(regularAttractionLoops, deviceInformation)

  return attractionLoop?.fields;
}

/**
 * @param attractionLoops A collection of IAttractionLoopInfo objects to filter.
 * @param kioskStoreNumber The store number the kiosk belongs to.
 * @param kioskBrand The brand the kiosk belongs to.
 * @returns The IAttractionLoopInfo object that matches either the storeNumber or the brand.
 */
export const getAttractionLoopInfoByStoreNumberOrBrand = (attractionLoops: Data<IAttractionLoopInfo>[], deviceInformation: IDeviceInformation): Data<IAttractionLoopInfo> => {
  const bestOptionByStoreNumber = attractionLoops?.find(option => option?.fields?.target?.includes(deviceInformation.storeNumber));

  if (!bestOptionByStoreNumber) {
    const bestOptionByBrand = attractionLoops?.find(option => option?.fields?.target?.includes(deviceInformation.brand));
    return bestOptionByBrand;
  }

  return bestOptionByStoreNumber;
}

/**
 * Filters the storageOptions array by title based on a given string filter.
 * @param storageOptions The array with all the storage options coming from Contentful.
 * @param dimensions The unit dimensions to match with the returned data image
 * @param size The size filter to apply to the array
 * @returns An Array with the storage options that match the filter. Should be just one item matching the filter.
 */
const filterStorageOptions = (storageOptions: ISelfStorageOption[], dimensions: string, size: string) => {
  const elemsWithGroupNameSizeAndSizePresentInDeveloperData = storageOptions
    .filter(storageOption => storageOption.fields.groupName === size)
    .filter(storageOption => storageOption.fields.developerData.size);

  if (elemsWithGroupNameSizeAndSizePresentInDeveloperData.some(storageOption => storageOption.fields.developerData.size?.includes(dimensions))) {
    return elemsWithGroupNameSizeAndSizePresentInDeveloperData
      .filter(storageOption => storageOption.fields.developerData.size?.includes(dimensions));
  } else {
    return elemsWithGroupNameSizeAndSizePresentInDeveloperData;
  }
};

const formatAddress = (address: IStoreAddress): string => `${address.city}, ${address.stateAbbreviation}`;

export const getNoInventoryURL = (address: IStoreAddress): string => encodeURI(`${process.env.KIOSK_SEARCH_PAGE_URL}${formatAddress(address)}`);

/**
 *  Removes invalid chars from the ADDRESS tag in the xml that goes to Open Edge. *
 * @param oneTimePaymentXml xml in the format that Open Edge processes.
 * @param invalidChars a list of comma separated values with no spaces between values,
 * that are the chars that need to be removed from the ADDRESS tag.
 * Examples: "#" OR "#,$" OR "#,$,%"
 * @returns IF invalidChars is empty, null or undefined, it returns oneTimePaymentXml,
 * IF invalidChars contains values, it returns a new xml with the chars removed from the ADDRESS tag.
 */
export const getXMLWithoutInvalidCharsInAddressForOTP = (oneTimePaymentXml: string, invalidChars?: string): string => {
  if (!invalidChars) {
    return oneTimePaymentXml;
  }

  const chars: string[] = invalidChars.split(",");

  const xmlDoc = new DOMParser().parseFromString(oneTimePaymentXml, "text/xml");
  const addressNode = xmlDoc.getElementsByTagName("ADDRESS")[0].childNodes[0];

  let newAddress: string = "";
  for (const char of chars) {
    newAddress = addressNode.nodeValue.replaceAll(char, "");
    addressNode.nodeValue = newAddress;
  }

  const serializer = new XMLSerializer();
  const serializedString = serializer.serializeToString(xmlDoc);

  return serializedString;
};

export const wait = async (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
