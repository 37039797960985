// Constants
import {
  deviceInformationStorageKey,
  featureTogglesStorageKey,
  proximitySensorThresholdKey,
  rentUnitContactKey,
  rentUnitStorageKey,
  storeInformationStorageKey
} from "@/scripts/constant-types/localStorageKeys";

// Types
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";
import IFeatureToggle from "@/interfaces/device/features/IFeatureToggle";
import IProximitySensorThreshold from "@/interfaces/context/proximity-sensor/IProximitySensorThreshold";
import IRentUnit from "@/interfaces/context/rent-unit/IRentUnit";
import { IRentUnitContact } from "@/interfaces/context/rent-unit/IRentUnitContact";
import { IStoreSchedule } from "@/interfaces/hours-of-operation/IHoursOfOperation";

const getLocalStorageObject = (localStorageObjectKey: string) => {
  const localData = typeof window !== "undefined" && localStorage.getItem(localStorageObjectKey);
  return localData;
};

const getFeatures = (): IFeatureToggle[] => {
  const dataFromLocalStorage = typeof window !== "undefined" && getLocalStorageObject(featureTogglesStorageKey);
  const result = dataFromLocalStorage
    ? JSON.parse(dataFromLocalStorage) as IFeatureToggle[]
    : [];

  return result;
};

const getDeviceInfoFromLocalStorage = (): IDeviceInformation => {
  const dataFromLocalStorage = typeof window !== "undefined" && getLocalStorageObject(deviceInformationStorageKey);
  return dataFromLocalStorage
    ? JSON.parse(dataFromLocalStorage) as IDeviceInformation
    : {};
};

const getHoursOfOperation = (): IStoreSchedule | undefined => {
  const dataFromLocalStorage = typeof window !== "undefined" && getLocalStorageObject(storeInformationStorageKey);
  return dataFromLocalStorage
    ? JSON.parse(dataFromLocalStorage) as IStoreSchedule
    : undefined;
};

const getProximitySensorThresholdValues = (): IProximitySensorThreshold => {
  const dataFromLocalStorage = typeof window !== "undefined" && getLocalStorageObject(proximitySensorThresholdKey);
  return dataFromLocalStorage
    ? JSON.parse(dataFromLocalStorage) as IProximitySensorThreshold
    : {};
};

const getRentUnitValues = (): IRentUnit => {
  const dataFromLocalStorage = typeof window !== "undefined" && getLocalStorageObject(rentUnitStorageKey);
  return dataFromLocalStorage
    ? JSON.parse(dataFromLocalStorage) as IRentUnit
    : {};
};

const getRentUnitContactValues = (): IRentUnitContact => {
  const dataFromLocalStorage = typeof window !== "undefined" && getLocalStorageObject(rentUnitContactKey);
  return dataFromLocalStorage
    ? JSON.parse(dataFromLocalStorage) as IRentUnitContact
    : {};
};

const saveDeviceInfoOnLocalStorage = (deviceInformationToSave: IDeviceInformation) => {
  setLocalStorageObject(deviceInformationStorageKey, deviceInformationToSave);
};

const setLocalStorageObject = (localStorageKey: string, localStorageObject: any) => {
  localStorage.setItem(localStorageKey, JSON.stringify(localStorageObject));
};

const storeHasMap = (): boolean => {
  const deviceInformation = getDeviceInfoFromLocalStorage();

  return !!deviceInformation.mapId;
};

export {
  getDeviceInfoFromLocalStorage,
  getFeatures,
  getHoursOfOperation,
  getLocalStorageObject,
  getProximitySensorThresholdValues,
  getRentUnitContactValues,
  getRentUnitValues,
  saveDeviceInfoOnLocalStorage,
  setLocalStorageObject,
  storeHasMap,
};
