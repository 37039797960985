/**
 * CAUTION, THIS FUNCTION MIGHT NOT WORK FOR DAYLIGHT SAVING TIME CHANGE.
 * @param date The initial date to which you want to add or subtract minutes to.
 * @param minutes The number of minutes that you want to add, or negative values if you want to subtract.
 * @returns A new Date object with the added or subtracted minutes.
 */
const addMinutesToDate = (date: Date, minutes: number): Date => new Date(date.getTime() + minutes * 60000);

/**
 * Converts a given number of seconds to a Human readable format. E.g | 90 Seconds ==> 1 Minute, 30 Seconds.
 * @param timeInSeconds Number of seconds to parse to string.
 * @returns A string with the time in a human readable format.
 */
const convertTimeInSecondsToHumanReadableString = (timeInSeconds: number): string => {
  if (!timeInSeconds || timeInSeconds <= 0) {
    return "0 Seconds";
  }

  let minutes: number = Math.floor(timeInSeconds / 60);
  let hours: number = Math.floor(minutes / 60);
  const seconds: number = (timeInSeconds % 60);
  let hoursText: string;
  if (minutes >= 60) {
    hours = Math.floor(minutes / 60);
    
    hoursText = hours == 1
      ? hours.toString() + " Hour"
      : hours.toString() + " Hours";
    minutes = minutes % 60;
  }

  const minutesText = minutes == 1
    ? minutes.toString() + " Minute"
    : minutes.toString() + " Minutes";

  const secondsText = seconds == 1
    ? seconds.toString() + " Second"
    : seconds.toString() + " Seconds";

  if (hoursText) {
    return `${hoursText}, ${minutesText}, ${secondsText}`;
  }
  if (minutes > 0) {
    return `${minutesText}, ${secondsText}`;
  }
  
  return secondsText;
};

/**
 * Gets the current time.
 * @returns The current time as a number
 */
const getCurrentTime = (): number => new Date().getTime();

/**
 * Gets the current date.
 * @returns The current Date.
 */
const getDate = (): Date => new Date();

const getLocalTimestampFromDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

/**
 * Calculates the number of days between two dates and returns the absolute number.
 * @param date1 A date to compare.
 * @param date2 A date to compare
 * @returns The number of days between the two dates
 */
const getNumberOfDaysBetweenTwoDates = (date1: Date, date2: Date): number => {
  if (!date1 || !date2) {
    throw new Error("Dates cannot be null or undefined")
  }

  const differenceInTime = date2.getTime() - date1.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return Math.trunc(Math.abs(differenceInDays));
};

/**
 * @param startDate The start date for tbe range.
 * @param endDate The end date for the range.
 * @returns Whether the current date is within the start and end date range.
 */
const isCurrentDateWithinRange = (startDate: Date, endDate?: Date): boolean => {
  const now: Date = getDate();

  return (!!startDate && now >= startDate) && (!endDate || now <= endDate);
};

/**
 * Returns whether the current time is within the date and 30 minutes before the date.
 */
const isCurrentTimeWithin30MinutesRange = (date: Date): boolean => {
  if (!date) {
    return false;
  }

  const thirtyMinutesBeforeDate = addMinutesToDate(date, -30);
  const now: Date = getDate();

  return now >= thirtyMinutesBeforeDate && now <= date;
};

const getDayOfMonthString = (date: Date) => {
  const dayOfMonth = date.getDate();
  const month = date.getMonth();

  if (month == 1) {
    if (dayOfMonth == 29) {
      return "28th";
    }
  }

  switch (dayOfMonth) {
    case 1:
      return "1st"
    case 2:
      return "2nd";
    case 3:
      return "3rd";
    case 21:
      return "21st"
    case 22:
      return "22nd";
    case 23:
      return "23rd";
    case 31:
      return "30th";
  }

  return `${dayOfMonth}th`;
}

/**
 * Formats a date in string format into mm/dd/yyyy string format
 * @param inputDateString The date string from the inpute date string
 * @returns The date string in format mm/dd/yyyy
 */
const formatDateFromDateInputField = (inputDateString: string) => {
  const date = new Date(inputDateString);
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth() returns 0-based index
  const day = String(date.getUTCDate()).padStart(2, "0");
  const year = date.getUTCFullYear();
    
  return `${month}/${day}/${year}`;
}

export {
  addMinutesToDate,
  convertTimeInSecondsToHumanReadableString,
  formatDateFromDateInputField,
  getCurrentTime,
  getDate,
  getDayOfMonthString,
  getLocalTimestampFromDate,
  getNumberOfDaysBetweenTwoDates,
  isCurrentDateWithinRange,
  isCurrentTimeWithin30MinutesRange,
}
