// Hooks
import { useStoreDetailsHandler } from "@/context/store-details/StoreDetailsHandler";

// Node Modules
import React, {
  createContext,
  useContext,
} from "react";

// Types
import IBaseContextProvider from "@/interfaces/context/IBaseContextProvider";
import IStoreDetailsContext from "@/interfaces/store-details/IStoreDetailsContext";

const StoreDetailsContext = createContext<IStoreDetailsContext | undefined>(undefined);

const useStoreDetailsContext = () => {
  const context = useContext(StoreDetailsContext);
  if (context === undefined) {
    throw new Error("useStoreDetailsContext must be used within a StoreDetailsContextProvider");
  }

  return context;
};

const StoreDetailsContextProvider = ({
  children,
}: IBaseContextProvider) => {
  const {
    address,
    adminFee,
    hoursOfOperation,
    storeState,
    isPendingDisaster,
    revalidateStoreState,
  } = useStoreDetailsHandler();

  const storeDetailsContext: IStoreDetailsContext = {
    address,
    adminFee,
    hoursOfOperation,
    stateAbbreviation: address?.stateAbbreviation ?? "",
    storeState,
    isPendingDisaster,
    revalidateStoreState,
  };

  return (
    <StoreDetailsContext.Provider
      value={storeDetailsContext}
    >
      {children}
    </StoreDetailsContext.Provider>
  );
};

export {
  StoreDetailsContext,
  StoreDetailsContextProvider,
  useStoreDetailsContext,
};
