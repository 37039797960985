const deviceInformationStorageKey = "DEVICE_INFORMATION";
const featureTogglesStorageKey = "FEATURES";
const proximitySensorThresholdKey = "PROX_SENSOR_THRESHOLD_VALUES";
const rentUnitStorageKey = "RENT_UNIT";
const rentUnitContactKey = "RENT_UNIT_CONTACT";
const rentUnitStoreDetailsStorageKey = "RENT_UNIT_STORE_DETAILS";
const storeInformationStorageKey = "STORE_INFORMATION";

const sessionStorageKeys = {
  gaSessionUserId: "sessionUserId",
};

export {
  deviceInformationStorageKey,
  featureTogglesStorageKey,
  proximitySensorThresholdKey,
  rentUnitContactKey,
  rentUnitStorageKey,
  rentUnitStoreDetailsStorageKey,
  sessionStorageKeys,
  storeInformationStorageKey,
};
