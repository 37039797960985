const onClickWithDataLayerEvent = (onClick: () => void, dataLayerEventName?: string): void => {
  onClick();
  if (dataLayerEventName) {
    pushEvent({
      event: dataLayerEventName,
    });
  }
};

const getDataLayer = (): any[] => typeof window !== "undefined" && window.dataLayer || [];

const pushEvent = (event: any): any[] => {
  const dataLayer = getDataLayer();
  dataLayer.push(event);

  return dataLayer;
};

const setCustomDimensions = async (ozStoreId: string) => {
  if (ozStoreId) {
    const event = {
      event: "kiosk_page_loaded",
      storeCSID: ozStoreId,
      siteType: "ESS_2.0",
    };

    pushEvent(event);
  }
};

export default {
  onClickWithDataLayerEvent,
  getDataLayer,
  pushEvent,
  setCustomDimensions,
};
