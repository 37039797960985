// Types
import IHoursOfOperationDetails from "@/interfaces/splash-screen/IHoursOfOperationDetails";

type IHoursOfOperationDetailKey = keyof IHoursOfOperationDetails;

const day: IHoursOfOperationDetailKey[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const getCurrentDay = (): IHoursOfOperationDetailKey => {
  return day[new Date().getDay()];
}

const getYesterdayDate = (now: Date) => {
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  return yesterday;
}

const getYesterdayDayName = (now: Date) => {
  const yesterday = getYesterdayDate(now)
  return day[yesterday.getDay()];
}

const getOpeningHours = (hoursOfOperation: IHoursOfOperationDetails): string | undefined => {
  const kioskDay = getCurrentDay();
  
  if (hoursOfOperation[kioskDay ] == "Closed") {
    return hoursOfOperation[kioskDay];
  }

  const opening = hoursOfOperation[kioskDay as keyof IHoursOfOperationDetails]?.split("-")[0];
  return opening
    ? opening.trim()
    : undefined;
}

const getClosingHours = (hoursOfOperation: IHoursOfOperationDetails) => {
  const kioskDay = getCurrentDay();
  
  if (hoursOfOperation[kioskDay] == "Closed") {
    return hoursOfOperation[kioskDay];
  }

  const closing = hoursOfOperation[kioskDay]?.split("-")[1];
  return closing
    ? closing.trim()
    : undefined;
}

const isAValidSchedule = (hoursOfOperation: IHoursOfOperationDetails) => {
  if (!hoursOfOperation || Object.keys(hoursOfOperation).length === 0) {
    return false;
  }

  const keysAsDays = [
    "friday",
    "monday",
    "saturday",
    "sunday",
    "thursday",
    "tuesday",
    "wednesday",
  ];
 
  for (const day of keysAsDays) {
    if (!hoursOfOperation.hasOwnProperty(day)) {
      return false;
    }
  }
    
  return true;
}

const isScheduleInCloseHours = (hoursOfOperation: IHoursOfOperationDetails) => {
  const isClose = true;
  const isOpen = false
  if (!hoursOfOperation || !isAValidSchedule(hoursOfOperation)) {
    return false;
  }

  let now = new Date();

  const isTimeZoneAvailable = hoursOfOperation.timeZone && hoursOfOperation.timeZone.length;

  if (isTimeZoneAvailable) {
    const dateWithTimeZone = new Date(new Date().toLocaleString("en-US", {
      timeZone: hoursOfOperation.timeZone,
    }));

    now = dateWithTimeZone;
  }
    
  const currentTimestamp = now.valueOf();

  const currentDay = getCurrentDay();

  const todaySchedule = hoursOfOperation[currentDay];

  if (!todaySchedule || todaySchedule.toLocaleLowerCase() == "closed") {
    return isClose;
  }
  
  const todayStartTimestamp = getScheduleTimestamp(todaySchedule, now, "startHour");
  const todayEndTimestamp = getScheduleTimestamp(todaySchedule, now, "endHour");

  const currentlyOpenInYesterdaysSchedule = isCurrentlyOpenInYesterdaysSchedule(now, hoursOfOperation);
  const isCurrentlyOpenInTodaysSchedule = todayStartTimestamp <= currentTimestamp && currentTimestamp < todayEndTimestamp;
 
  if (currentlyOpenInYesterdaysSchedule || isCurrentlyOpenInTodaysSchedule) {
    return isOpen;
  } else {
    return isClose;
  }
}

const isCurrentlyOpenInYesterdaysSchedule = (now: Date, hoursOfOperation: IHoursOfOperationDetails) => {
  const yesterdayDayName = getYesterdayDayName(now);

  const yesterdaySchedule = hoursOfOperation[yesterdayDayName];

  if (!yesterdaySchedule) {
    return false;
  }

  const yesterdayScheduleClosingTime = yesterdaySchedule!.split("-")[1];

  const doesYesterdaysScheduleEndsToday = isTimeOnNextDay(yesterdayScheduleClosingTime, true);

  if (!doesYesterdaysScheduleEndsToday) {
    return false;
  }

  const yesterdayScheduleEndDate = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
  const yesterdayClosingTimestamp: number = new Date(`${yesterdayScheduleEndDate} ${yesterdayScheduleClosingTime}`).valueOf();

  const currentTimeStamp = now.valueOf();

  const isOpenInYesterdaysSchedule = yesterdayClosingTimestamp > currentTimeStamp;
  return isOpenInYesterdaysSchedule;
}

const isTimeOnNextDay = (endTime: string, isEndHour: boolean) => {
  if (!endTime) {
    return false;
  }

  const matchResult = endTime.match(/AM/g);
  if (matchResult && isEndHour && matchResult.length === 1) {
    return true;
  } else {
    return false
  }
}
const getScheduleTimestamp = (daySchedule: string, now: Date, type: "startHour" | "endHour") => {
  const isEndHour = type === "endHour";
  const timeSchedule = daySchedule.split("-");
  const startTime = timeSchedule[0];
  const endTime = timeSchedule[1];

  const nextDay = isTimeOnNextDay(endTime, isEndHour);

  const validatedDate = new Date();
  validatedDate.setFullYear(now.getFullYear());
  validatedDate.setMonth(now.getMonth());
  validatedDate.setDate(now.getDate() + (nextDay
    ? 1
    : 0));

  const todayInString = `${validatedDate.getFullYear()}-${validatedDate.getMonth() + 1}-${validatedDate.getDate()}`;

  const timeToUse = isEndHour
    ? endTime
    : startTime;

  const dayWithScheduleTime = new Date(`${todayInString} ${timeToUse}`);
  return dayWithScheduleTime.valueOf();
}

const hoursOfOperationParsingUtils = {
  getOpeningHours,
  getClosingHours,
  isScheduleInCloseHours,
}

export { hoursOfOperationParsingUtils }
